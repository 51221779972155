<template>
  <div class="choice">
    <Nav navType="fun_text" textCon="为您优选" >
      <template v-slot:rightText>
        <span class="iconfont iconzhuanfa" style="color:#000;font-size:20px"></span>
      </template>
    </Nav>
    <div class="bgimg"><img src="https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=4041845150,102448655&fm=26&gp=0.jpg" alt=""></div>
  </div>
</template>
<script>
import Nav from "@components/public/searchTop";
export default {
  data(){
    return{

    }
  },
  components:{
    Nav
  }
}
</script>
<style lang="scss" scoped>
.choice{
  width:100%;
  height: auto;
  padding-top:45px;
  background-color: #f4f4f4;
  .bgimg{
    width:100%;
    height: 140px;
    img{
      width:100%;
      height: 100%;
    }
  }
}
</style>